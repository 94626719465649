import React from "react";
import Box from "@mui/material/Box";
import maticLogo from "../../static/matic-logo.png";
import usdtLogo from "../../static/usdt-logo.png";
import usdcLogo from "../../static/usdc-icon.png";
import euroLogo from "../../static/euroLogo.png";
import dollarLogo from "../../static/dollarLogo.png";

const PaymentIcon = ({ currency }) => {
  const getIcon = () => {
    if (currency.toLowerCase() === "matic") {
      return maticLogo;
    } else if (currency.toLowerCase() === "usdt") {
      return usdtLogo;
    } else if (currency.toLowerCase() === "usdc") {
      return usdcLogo;
    } else if (currency.toLowerCase() === "eur") {
      return euroLogo;
    } else if (currency.toLowerCase() === "usd") {
      return dollarLogo;
    } else {
      return maticLogo;
    }
  };
  return <Box component={"img"} src={getIcon()} sx={{ width: "100%" }} />;
};

export default PaymentIcon;
